import React, { useEffect, useRef, useState } from "react";
// import {useNavigate} from 'react-router-dom';

import "./App.scss";
import api from "./common/api";
import Root from "./common/components/root";
import Navigation from "./pages/routes/auth/MainIndex";
import HeaderFooterLayout from "./pages/home/HeaderFooterLayout";
import { SetCssVariable, useNav } from "./common/utils/helper";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setNavigateHelper } from "./common/utils/navigateHelper";
import {
  LOGIN_ACTION_KEY_SCRIPT,
  REGISTERED_ACTION_KEY_SCRIPT,
} from "./common/utils/pageSettingHelper";

export default function App(props) {
  // Remove console log in production
  if (process.env.NODE_ENV === "production")
    console.log = function no_console() {};

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);

  // stop right click
  if (!isMobileDevice) {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }

  const theme = useSelector((state) => state.styling.theme);
  const { i18n } = useTranslation();
  const navigate = useNav();
  const [enableMouseOver, setEnableMouseOver] = useState(false);
  const targetElement = useRef(null);
  setNavigateHelper(navigate);

  //iframe created by ourself need to put classname brand-iframe-container in order to support change language and theme
  function sendMessageToIframe(data) {
    const iframes = document.getElementsByClassName("brand-iframe-container");
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      iframe.contentWindow.postMessage(data, "*");
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleReceivedMessage);

    return () => {
      window.removeEventListener("message", handleReceivedMessage);
    };
  }, []);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      console.log(`document clicked`);
      window.parent.postMessage(
        {
          action: "clickedInspectElement",
        },
        "*"
      );
      event.stopPropagation();
      setEnableMouseOver(false);
    };

    window.parent.postMessage(
      {
        action: "mouseOverStateChanged",
        mouseOverState: enableMouseOver,
      },
      "*"
    );

    if (enableMouseOver) {
      // Attach the click listener to the document
      document.addEventListener("click", handleDocumentClick, true);

      return () => {
        // Cleanup the event listener when the component unmounts
        document.removeEventListener("click", handleDocumentClick, true);
      };
    }
  }, [enableMouseOver]);

  useEffect(() => {
    if (!enableMouseOver && targetElement.current) {
      //to update back to the original border when inspect mode end.
      targetElement.current.target.style.border = targetElement.current.border;

      const result = getAllRelatedCssVariable(targetElement.current.target);
      console.log(`here result testing, `, result);
      window.parent.postMessage(
        {
          action: "inspectElementCssVariable",
          elementCssVariable: result,
        },
        "*"
      );

      targetElement.current = null;
    }
  }, [enableMouseOver]);

  function handleReceivedMessage(event) {
    const currentDomain = window.location.hostname;
    const currentPort = window.location.port;

    console.log(currentDomain + currentPort + " receive message ", event);
    const receivedMessage = event.data;
    if (receivedMessage.action == "updateCss" && receivedMessage.data) {
      const item = receivedMessage.data;
      SetCssVariable(item.name, item.value);
    }

    if (receivedMessage.action == "enableMouseOver" && receivedMessage.data) {
      const enableMouseOver = receivedMessage.data?.enableMouseOver ?? false;
      setEnableMouseOver(enableMouseOver);
    }
  }

  useEffect(() => {
    sendMessageToIframe({ language: i18n.language });
  }, [i18n.language]);

  useEffect(() => {
    sendMessageToIframe({ theme: theme });
  }, [theme]);

  useEffect(() => {
    api.PageSetting({
      name: "all",
      success: (res) => {
        if (res.data && res.data.length > 0) {
          const data = res.data[0];

          //head
          insertElement(data.HeadScript, (element) => {
            document.head.appendChild(element);
          });

          //body start
          const firstChild = document.body.firstChild;
          insertElement(data.BodyScriptStart, (element) => {
            document.body.insertBefore(element, firstChild);
          });

          //body end
          insertElement(data.BodyScriptEnd, (element) => {
            document.body.appendChild(element);
          });

          //registerSuccess script
          insertScriptFunction(
            REGISTERED_ACTION_KEY_SCRIPT,
            data.RegisterScript ?? ""
          );

          //login script
          insertScriptFunction(LOGIN_ACTION_KEY_SCRIPT, data.LoginScript ?? "");
        }
      },
    });
  }, []);

  function insertScriptFunction(functionName, functionContent) {
    const successRegister = `function ${functionName}() {
      ${functionContent}
    }`;
    // Create a script element
    const scriptElement = document.createElement("script");

    scriptElement.textContent = successRegister;

    // Append the script element to the head
    document.head.appendChild(scriptElement);
  }

  function convertStringToHtml(stringHtml) {
    const htmlElement = document.createElement("div");
    htmlElement.innerHTML = stringHtml;
    return htmlElement;
  }

  function insertElement(stringHtml, insertAction) {
    const htmlElement = convertStringToHtml(stringHtml);

    Array.from(htmlElement.children).forEach((element) => {
      if (element.tagName.toLowerCase() == "script") {
        const scriptElement = document.createElement("script");
        scriptElement.textContent = element.textContent;
        insertAction(scriptElement);
      } else {
        insertAction(element);
      }
    });
  }

  function getAllRelatedCssVariable(target) {
    let variableInfo = new Map();
    if (target.className) {
      const resultStyleSheet = getCssVariableNamesFromStyleSheet(
        target.className,
        variableInfo
      );
      if (resultStyleSheet) variableInfo = resultStyleSheet;
    }
    const resultInline = getCssVariableNamesFromInlineStyle(
      target,
      variableInfo
    );

    resultInline.forEach((values, key) => {
      if (variableInfo.has(key)) {
        variableInfo.get(key).push({
          value: values,
          active: true, // Initially mark as inactive
        });

        variableInfo.get(key).slice(-2)[0].active = false; //set previous to inactive
      } else {
        variableInfo.set(key, [
          {
            value: values,
            active: true,
          },
        ]);
      }
    });

    const outObject = {};
    variableInfo.forEach((value, key) => {
      // Assuming that all keys are strings or can be easily converted to strings
      outObject[key] = value;
    });
    return outObject;
  }

  function getCssVariableNamesFromStyleSheet(classNames) {
    const styleSheets = Array.from(document.styleSheets);
    const variableInfo = new Map();
    const classList = classNames.split(" ").map((cls) => `.${cls}`);

    styleSheets.forEach((sheet, index) => {
      let rules;
      try {
        rules = Array.from(sheet.cssRules);
      } catch (e) {
        return;
      }

      rules.forEach((rule, ruleIndex) => {
        if (rule.selectorText) {
          const selectorMatches = classList.some((className) => {
            const regex = new RegExp(
              `(?:^|\\s|\\.)${className}(?:\\s|$|\\.|:)`
            );
            return regex.test(rule.selectorText);
          });

          if (selectorMatches) {
            const cssText = rule.style.cssText;
            const propertyMatches = cssText.match(/[\w-]+:\s*var\(--[\w-]+\)/g);

            if (propertyMatches) {
              propertyMatches.forEach((propertyMatch) => {
                const [property, value] = propertyMatch.split(":");
                const propKey = property.trim();
                const propValue = extractCssVariableName(value.trim());

                if (!variableInfo.has(propKey)) {
                  variableInfo.set(propKey, []);
                }
                variableInfo.get(propKey).push({
                  value: propValue,
                  active: false, // Initially mark as inactive
                });

                // Mark the latest matched value as active (assuming this rule overrides previous ones)
                // variableInfo.get(propKey).forEach((v) => (v.active = false)); // Reset previous entries
                variableInfo.get(propKey).slice(-2)[0].active = false; // Mark previous as inactive
                variableInfo.get(propKey).slice(-1)[0].active = true; // Mark last as active
              });
            }
          }
        }
      });
    });

    return variableInfo;
  }

  function extractCssVariableName(cssVariableExpression) {
    const regex = /var\((--[\w-]+)\)/;
    const match = cssVariableExpression.match(regex);
    return match ? match[1] : cssVariableExpression;
  }

  function getCssVariableNamesFromInlineStyle(element) {
    const variableInfo = new Map();
    const styles = element.style; // Access inline styles
    const cssText = styles.cssText; // Get the CSS text of inline styles
    // Regular expression to find CSS variable usage
    const regex = /[\w-]+:\s*var\(--[\w-]+\)/g;
    const matches = cssText.match(regex);

    if (matches) {
      matches.forEach((match) => {
        const [property, value] = match.split(":");
        variableInfo.set(property.trim(), extractCssVariableName(value.trim()));
      });
    }

    return variableInfo;
  }

  const handleMouseOver = (event) => {
    // Save the original border style before changing it
    const currentStyle = event.target.style.border;
    targetElement.current = { target: event.target, border: currentStyle };
    event.target.style.border = "2px solid red"; // Adding a border
  };

  const handleMouseOut = (e) => {
    // Restore the original border style
    if (targetElement?.current?.border != null) {
      e.target.style.border = targetElement.current.border;
    }
  };

  return (
    <div
      onMouseOver={enableMouseOver ? handleMouseOver : null}
      onMouseOut={enableMouseOver ? handleMouseOut : null}
    >
      <Root navigate={navigate}>
        <HeaderFooterLayout>
          <Navigation />
        </HeaderFooterLayout>
      </Root>
    </div>
  );
}
